<template>
  <v-card
    id="product-categories-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <product-category-tab
          :data-params="productCategoryData"
          :tab="tab"
          :option="option"
          @changeTab="changeTab"
          @save="saveProductCategory"
        ></product-category-tab>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import useSelectOptions from '@core/utils/useSelectOptions'
import { error, success } from '@core/utils/toasted'
import {
  postProductCategory, putProductCategory, getProductCategory,
} from '@api'

// demos
import productCategoryTab from './ProductCategoryTab.vue'

export default {
  components: {
    productCategoryTab,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()

    const tab = ref(0)
    const option = ref(0)
    const productCategoryId = ref(null)
    const pageData = ref()
    const filterData = ref()
    const productCategoryData = ref({})
    const postData = ref({
      name: null,
      description: null,
      status: null,
      facility_id: null,
    })
    const putData = ref({
      name: null,
      description: null,
      status: null,
      facility_id: null,
    })

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const saveProductCategory = async data => {
      let resp = null
      if (option.value === 1) {
        resp = await postProductCategory(data)
      }

      if (option.value === 3) {
        resp = await putProductCategory(data)
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      productCategoryData.value = {}
    }

    const goBack = () => {
      router.replace({
        name: 'views-products-categories-list',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option || 2
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      productCategoryData.value.facility_id = route.value.params.facility_id
      productCategoryId.value = route.value.params.id

      if (productCategoryId.value) {
        const resp = await getProductCategory(productCategoryId.value)
        if (resp.ok) {
          productCategoryData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }

      if (option.value !== 1) {
        tabs.value.splice(1, 1)
      }
    })

    return {
      tab,
      tabs,
      option,
      productCategoryId,
      productCategoryData,
      postData,
      putData,
      pageData,
      filterData,

      changeTab,
      saveProductCategory,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
